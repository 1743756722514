import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {lighten, makeStyles, Theme} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Add from "@material-ui/icons/Add";
import classNames from "classnames";
import {Cancelable, debounce} from "lodash";
import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {createPortal} from "react-dom";
import {Key} from "ts-key-enum";
import {useComponentBecameReadonly} from "../helpers/bbdoc";
import {CandidateResponse, InterviewDocQuestion} from "../reducers/types";
import {usePrevious, useUnsavedChangesWarning} from "../util/hooks";

export const COMMENT_BOX_MARGIN_PX = 15;
const ACTIVE_COMMENT_INDENT_PX = 40;
const NEW_COMMENT_BUTTON_WIDTH = 150;
const NEW_COMMENT_EXPANDED_HEIGHT_PX = 140;
const DOC_BODY_TEXT_FIELD_MIN_HEIGHT_PX = 180;
const COLLAPSED_MAX_HEIGHT_PX = 40;
const AVATAR_MARGIN_RIGHT_PX = 12;
const BOX_BORDER_RADIUS = 8;
const ON_RESPONSE_CHANGE_DEBOUNCE_WAIT_MS = 2000;
const DEFAULT_USER_NAME = "You";

export const INPUT_DATA_ATTR = "data-input";

interface Props {
  type: "default" | "newCommentCollapsed" | "newCommentExpanded";
  candidateResponse: CandidateResponse;
  active: boolean;
  topOffset: number;
  candidateName?: string;
  question?: InterviewDocQuestion;
  /**
   * If provided the text field will be rendered in this element instead of the
   * comment box.
   */
  textFieldParentElement?: HTMLElement;
  preventPositionAnimation?: boolean;
  readonly: boolean;
  onFocus: (candidateResponse: CandidateResponse) => void;
  onResponseChange?: (candidateResponseValue: string) => void;
  onInputHeightChange?: () => void;
  onEscapeKeyPress?: () => void;
  goToNextComment?: () => void;
  goToPreviousComment?: () => void;
  highlightedElements?: HTMLElement[];
}

const useStyles = makeStyles<Theme, Props>(
  (theme) => ({
    "@keyframes expandingNewCommentBox": {
      from: {
        borderRadius: 20,
        maxHeight: 32,
        width: NEW_COMMENT_BUTTON_WIDTH,
      },
      "99%": {
        borderRadius: BOX_BORDER_RADIUS,
        maxHeight: NEW_COMMENT_EXPANDED_HEIGHT_PX,
        width: "100%",
      },
      "100%": {
        maxHeight: "inherit",
      },
    },
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    root: {
      left: 0,
      width: "100%",
      position: "absolute",
      top: 0,
      "&:last-child": {
        paddingBottom: COMMENT_BOX_MARGIN_PX,
      },
    },
    animatePosition: (props) => {
      return {
        transition: props.preventPositionAnimation ? "" : "transform 0.25s",
      };
    },
    box: {
      background: "white",
      border: "solid 1px white",
      borderRadius: BOX_BORDER_RADIUS,
      boxShadow: "0 0 3px rgba(0,0,0,0.3)",
      cursor: "pointer",
      transition: "boxShadow 0.25s",
      width: "100%",
      "&:hover:not($active)": {
        boxShadow: "0px 1px 5px rgba(0,0,0,0.3)",
      },
      "$active &": {
        boxShadow: "0px 1px 6px rgba(0,0,0,0.4)",
      },
    },
    questionBox: {
      background: theme.bbdoc.palette.bb.lighter,
      borderColor: theme.bbdoc.palette.bb.main,
    },
    // This element takes up the full height of the new comment box so that
    // surrounding comments will start to reposition as this is transitioning
    // from a button to the new comment box.
    newCommentExpanded: {
      minHeight: NEW_COMMENT_EXPANDED_HEIGHT_PX,
    },
    expandingNewCommentBox: {
      animation: "$expandingNewCommentBox 0.25s forwards",
      "& > *": {
        animation: "$fadeIn 0.3s 0.1s forwards",
        opacity: 0,
      },
    },
    active: {
      zIndex: 1,
    },
    comment: {
      padding: "15px 15px 1px 15px",
      "&:not(:first-child)": {
        borderTop: `solid 1px ${lighten(theme.bbdoc.palette.bb.main, 0.6)}`,
        marginTop: 15,
      },
      "$questionBox &:only-child": {
        paddingBottom: 15,
      },
      "$collapsed &": {
        display: "flex",
      },
    },
    commentHeader: {
      alignItems: "center",
      display: "flex",
      marginBottom: 12,
      "$collapsed &": {
        marginRight: AVATAR_MARGIN_RIGHT_PX,
      },
    },
    commentAvatar: {
      color: theme.bbdoc.palette.candidate.main,
      height: 40,
      margin: "-3px -3px 0 -3px",
      width: 40,
    },
    questionAvatar: {
      alignItems: "center",
      borderRadius: "50%",
      color: "white",
      display: "flex",
      fontWeight: 500,
      height: 35,
      justifyContent: "center",
      width: 35,
    },
    candidateAvatar: {
      background: theme.bbdoc.palette.candidate.main,
    },
    bbAvatar: {
      background: theme.bbdoc.palette.bb.main,
    },
    commentName: {
      color: "black",
      fontFamily: "Google Sans, Arial",
      fontSize: 13,
      fontWeight: 500,
      marginLeft: AVATAR_MARGIN_RIGHT_PX,
      "$collapsed &": {
        display: "none",
      },
    },
    input: {
      marginTop: 0,
      "& *": {
        fontSize: 13,
        "$docBodyResponseContainer &": {
          fontSize: 14,
        },
      },
      "&& > div": {
        background: "white",
        "$questionBox & fieldset": {
          borderColor: lighten(theme.bbdoc.palette.bb.main, 0.4),
        },
      },
      ["$questionBox && > div:hover fieldset, " +
      "$questionBox && textarea:focus ~ fieldset"]: {
        borderColor: theme.bbdoc.palette.bb.main,
      },
    },
    commentText: {
      whiteSpace: "pre-wrap",
      fontSize: 13,
      minWidth: 0,
      "$collapsed &": {
        maxHeight: COLLAPSED_MAX_HEIGHT_PX,
        overflow: "hidden",
      },
    },
    commentTextEmphasized: {
      fontStyle: "italic",
    },
    addCommentButton: {
      background: "white",
      boxShadow: "0 1px 4px rgba(0,0,0,0.35)",
      padding: "5px 16px",
      width: NEW_COMMENT_BUTTON_WIDTH,
      "&:hover": {
        background: "#f9f9f9",
      },
    },
    saveLabel: {
      color: "#aaa",
      fontSize: 11,
      marginBottom: 5,
      marginTop: 1,
      textAlign: "right",
      "$questionBox &": {
        color: theme.bbdoc.palette.bb.main,
      },
    },
    fadeInSaveLabel: {
      animation: "$fadeIn 0.3s 1s forwards",
      opacity: 0,
    },
    docBodyResponseContainer: {
      background: theme.bbdoc.palette.bb.lighter,
      borderColor: theme.bbdoc.palette.bb.main,
      borderRadius: BOX_BORDER_RADIUS,
      borderStyle: "solid",
      marginTop: 15,
      padding: 15,
      "& textarea": {
        minHeight: DOC_BODY_TEXT_FIELD_MIN_HEIGHT_PX,
      },
    },
    commentTextContainer: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      marginBottom: 12,
      minWidth: 0,
      position: "relative",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    collapsed: {},
    seeMore: {
      background:
        "linear-gradient(to right, rgba(255, 255, 255, 0), white 15%, white)",
      bottom: 0,
      color: "#999",
      fontSize: 12,
      paddingBottom: 2,
      paddingLeft: 20,
      paddingRight: 10,
      paddingTop: 2,
      position: "absolute",
      right: 0,
    },
  }),
  {
    name: "BBDocCommentBox",
  },
);

export const BBDocCommentBox = forwardRef<any, Props>((props, ref) => {
  const classes = useStyles(props);
  const inputRef = useRef<HTMLTextAreaElement>();
  const prevActive = usePrevious(props.active);
  const [animatePosition, setAnimatePosition] = useState<boolean>(
    props.type === "default",
  );
  const [inputHeightStr, setInputHeightStr] = useState<string>();
  // The value that is displayed in the input; won't be the same as
  // props.candidateResponse.responseText if the changes haven't saved yet.
  const [displayValue, setDisplayValue] = useState(
    props.candidateResponse.responseText,
  );

  const onResponseChangeDebouncedRef = useRef<
    ((candidateResponseValue: string) => any) & Cancelable
  >(null);
  useEffect(() => {
    // @ts-ignore FIXME: strictNullChecks
    onResponseChangeDebouncedRef.current = props.onResponseChange
      ? debounce((candidateResponseValue: string) => {
          // Don't call `onResponseChange` if the value didn't actually change.
          if (candidateResponseValue === props.candidateResponse.responseText) {
            return;
          }
          // @ts-ignore FIXME: strictNullChecks
          props.onResponseChange(candidateResponseValue);
        }, ON_RESPONSE_CHANGE_DEBOUNCE_WAIT_MS)
      : null;
  }, [props.onResponseChange, props.candidateResponse.responseText]);

  const isSaved = (): boolean => {
    return props.candidateResponse.responseText === displayValue;
  };

  useUnsavedChangesWarning(!isSaved());

  // Save any unsaved changes when this component becomes readonly.
  useComponentBecameReadonly(props, () => {
    if (!isSaved()) {
      // Do it now instead of waiting for the debounced handler.
      cancelDebouncedOnResponseChange();
      // @ts-ignore FIXME: strictNullChecks
      props.onResponseChange(displayValue);
    }
  });

  useEffect(() => {
    if (!prevActive && props.active) {
      // Focus the input on the next frame, otherwise the scroll area will jump
      // to the top.
      requestAnimationFrame(() => {
        inputRef.current?.focus();
      });
    }
  }, [props.active]);

  // Ensure that props.onResponseChange isn't called after this component
  // unmounts.
  useEffect(() => {
    return () => cancelDebouncedOnResponseChange();
  }, []);

  // Keep track of whether or not the comment text is overflowing so we know
  // when to show the "see more" label.
  const commentTextRef = useRef<HTMLElement>();
  const isCommentTextOverflowing = (): boolean => {
    const commentTextHeight =
      commentTextRef.current?.getBoundingClientRect().height;
    // @ts-ignore FIXME: strictNullChecks
    return commentTextHeight >= COLLAPSED_MAX_HEIGHT_PX;
  };
  const [commentTextOverflowing, setCommentTextOverflowing] = useState<boolean>(
    isCommentTextOverflowing,
  );
  useLayoutEffect(() => {
    if (props.question || props.type !== "default") return;
    const overflowing = isCollapsed() && isCommentTextOverflowing();
    setCommentTextOverflowing(overflowing);
  });

  const getCurrentInputHeightStr = (): string => {
    // Note: Material-UI updates the height css property on the input whenever
    // the number of lines changes as the user is typing.
    // @ts-ignore FIXME: strictNullChecks
    return inputRef.current?.style?.height;
  };

  const onAnimationEnd = () => {
    if (props.type === "newCommentExpanded") {
      setAnimatePosition(true);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!(e.target instanceof HTMLTextAreaElement)) {
      return;
    }
    // if user presses down and they're at the end of an input, take them to the next question
    if (
      e.key === "ArrowDown" &&
      e.target.selectionStart === e.target.value.length
    ) {
      e.preventDefault();
      // @ts-ignore FIXME: strictNullChecks
      props.goToNextComment();
    }
    if (e.key === "ArrowUp" && e.target.selectionEnd === 0) {
      e.preventDefault();
      // @ts-ignore FIXME: strictNullChecks
      props.goToPreviousComment();
    }

    // If the user presses escape, leave the input and return to the doc
    if (
      e.key === Key.Escape ||
      (e.key === Key.Enter && e.metaKey) ||
      (e.key === Key.Enter && e.ctrlKey)
    ) {
      e.preventDefault();
      // @ts-ignore FIXME: strictNullChecks
      props.highlightedElements[0]?.focus();
      // @ts-ignore FIXME: strictNullChecks
      props.onEscapeKeyPress();
    }
    checkInputHeightChange();
  };

  const checkInputHeightChange = () => {
    if (!props.onInputHeightChange) return;
    if (inputHeightStr !== getCurrentInputHeightStr()) {
      setInputHeightStr(getCurrentInputHeightStr());
      // No need to call onInputHeightChange on the first key stroke.
      if (inputHeightStr) {
        props.onInputHeightChange();
      }
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setDisplayValue(value);
    onResponseChangeDebounced(value);
  };

  const preventPasteAction = (e: React.ClipboardEvent) => {
    e.preventDefault();
    return false;
  };

  const onResponseChangeDebounced = (candidateResponseValue: string) => {
    // @ts-ignore FIXME: strictNullChecks
    return onResponseChangeDebouncedRef.current(candidateResponseValue);
  };

  const cancelDebouncedOnResponseChange = () => {
    return onResponseChangeDebouncedRef.current?.cancel();
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    // Focus when the box is clicked (note, clicking directly on the input is
    // already handled in the onFocus).
    if (!(e.target as HTMLElement).matches(`[${INPUT_DATA_ATTR}]`)) {
      props.onFocus(props.candidateResponse);
    }
  };

  const retrySave = () => {
    // @ts-ignore FIXME: strictNullChecks
    props.onResponseChange(displayValue);
  };

  const hasInlineDocResponse = (): boolean => {
    return (
      props.question?.inputType === "body" && props.question?.inputRequired
    );
  };

  const failedToSave = (): boolean => {
    // @ts-ignore FIXME: strictNullChecks
    return props.candidateResponse.failedToSave;
  };

  const isCollapsed = (): boolean => {
    return (
      !props.active &&
      !props.readonly &&
      !Boolean(props.question) &&
      !failedToSave()
    );
  };

  const renderCommentHeader = (
    name: string,
    isQuestionSection: boolean,
  ): React.ReactNode => {
    return (
      <div className={classes.commentHeader}>
        {name ? (
          <div
            className={classNames(classes.questionAvatar, {
              [classes.candidateAvatar]: !isQuestionSection,
              [classes.bbAvatar]: isQuestionSection,
            })}
          >
            {getInitials(name)}
          </div>
        ) : (
          <AccountCircle className={classes.commentAvatar} />
        )}
        <Typography className={classes.commentName}>
          {name || DEFAULT_USER_NAME}
        </Typography>
      </div>
    );
  };

  const renderCommentBox = (
    name: string,
    commentText: string,
    questionId: string,
    isQuestionSection = false,
  ): React.ReactNode => {
    if (!isQuestionSection && hasInlineDocResponse()) {
      return props.textFieldParentElement
        ? createPortal(
            // id matches with aria-details from the <mark>
            <div className={classes.docBodyResponseContainer} id={questionId}>
              {renderCommentHeader(name, isQuestionSection)}
              {renderCommentTextField()}
            </div>,
            props.textFieldParentElement,
          )
        : null;
    }
    return (
      <div
        className={classes.comment}
        // id matches with aria-details from the <mark>
        id={questionId}
        role="comment"
        data-author={name || DEFAULT_USER_NAME}
      >
        {renderCommentHeader(name, isQuestionSection)}
        {isQuestionSection ? (
          <Typography
            className={classes.commentText}
            // This id allows the question being asked to be read by screenreaders
            // when the textarea (which has aria-describedby) is focused.
            id={`${questionId}_label`}
          >
            {commentText}
          </Typography>
        ) : (
          renderCommentTextField()
        )}
      </div>
    );
  };

  const renderCommentTextField = (): React.ReactNode => {
    const showTextOnly = isCollapsed() || props.readonly;
    return showTextOnly ? (
      <div className={classes.commentTextContainer}>
        {/* @ts-ignore FIXME: strictNullChecks*/}
        <Typography
          className={classNames(classes.commentText, {
            [classes.commentTextEmphasized]: props.readonly,
          })}
          ref={commentTextRef}
        >
          {displayValue || (props.readonly ? "No candidate response" : "")}
        </Typography>
        <input
          type="hidden"
          value={displayValue}
          {...{[INPUT_DATA_ATTR]: true}}
        />
        {props.readonly ? <div style={{height: 15}} /> : null}
        {isCollapsed() && commentTextOverflowing ? (
          <Typography className={classes.seeMore}>see more...</Typography>
        ) : null}
      </div>
    ) : (
      <>
        <TextField
          value={displayValue}
          multiline
          placeholder={props.question?.placeholder}
          onKeyDown={onKeyDown}
          onFocus={() => props.onFocus(props.candidateResponse)}
          onChange={onInputChange}
          onPaste={preventPasteAction}
          inputRef={inputRef}
          inputProps={{
            [INPUT_DATA_ATTR]: true,
            // This references the question being asked, and allows screenreaders
            // to easily read out the question when the textarea is focused.
            "aria-describedby": `${props.question?.id}_label`,
          }}
          className={classes.input}
          variant="outlined"
          fullWidth={true}
          margin="dense"
        />
        <Box display="flex" justifyContent="space-between">
          {renderPasteDisabledLabel()}
          {renderSaveLabel()}
        </Box>
      </>
    );
  };

  const renderPasteDisabledLabel = (): React.ReactNode => {
    return (
      <Typography className={classes.saveLabel}>Paste disabled.</Typography>
    );
  };

  const renderSaveLabel = (): React.ReactNode => {
    let label: React.ReactNode = "All changes auto-saved.";
    if (failedToSave()) {
      label = (
        <span>
          {"Failed to save comment. "}
          <Link onClick={retrySave}>Retry</Link>
        </span>
      );
    } else if (!isSaved()) {
      label = "Saving...";
    }
    return <Typography className={classes.saveLabel}>{label}</Typography>;
  };

  const renderContent = (): React.ReactNode => {
    switch (props.type) {
      case "newCommentCollapsed":
        return (
          <Button
            className={classes.addCommentButton}
            disableRipple={true}
            ref={ref}
            onClick={() => props.onFocus(props.candidateResponse)}
            size="small"
            startIcon={<Add style={{fontSize: 16}} />}
          >
            Add comment
          </Button>
        );
      case "newCommentExpanded":
        return (
          <div className={classes.newCommentExpanded} ref={ref}>
            <div
              className={classNames(
                classes.box,
                classes.expandingNewCommentBox,
                classes.active,
              )}
            >
              {renderCommentBox(
                // @ts-ignore FIXME: strictNullChecks
                props.candidateName,
                props.candidateResponse.responseText,
                props.candidateResponse.id,
              )}
            </div>
          </div>
        );
      case "default":
      default:
        const {question} = props;
        return (
          <div
            onClick={onClick}
            ref={ref}
            className={classNames(classes.box, {
              [classes.questionBox]: Boolean(props.question),
            })}
          >
            {question
              ? renderCommentBox(
                  question.questioner,
                  question.text,
                  question.id,
                  true,
                )
              : null}
            {!question || (question && question.inputRequired)
              ? renderCommentBox(
                  // @ts-ignore FIXME: strictNullChecks
                  props.candidateName,
                  props.candidateResponse.responseText,
                  props.candidateResponse.id,
                )
              : null}
          </div>
        );
    }
  };

  const translateX = props.active ? -ACTIVE_COMMENT_INDENT_PX : 0;
  const translateY = props.topOffset;

  // Prevent the comment box from flashing when a comment is deleted.
  if (isCollapsed() && !displayValue) return null;

  return (
    <div
      className={classNames(classes.root, {
        [classes.animatePosition]: animatePosition,
        [classes.collapsed]: isCollapsed(),
        [classes.active]: props.active,
      })}
      {...(isCollapsed()
        ? {
            tabIndex: 0,
            onFocus: () => {
              console.log(props.candidateResponse);
              props.onFocus(props.candidateResponse);
            },
          }
        : {})}
      onAnimationEnd={onAnimationEnd}
      style={{
        transform: `translate(${translateX}px, ${translateY}px)`,
      }}
    >
      {renderContent()}
    </div>
  );
});

function getInitials(fullName: string): string {
  return fullName
    .toUpperCase()
    .split(" ")
    .slice(0, 2)
    .map((name) => name[0])
    .join("");
}
