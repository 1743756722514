import {AnyAction} from "redux";
import {ThunkAction} from "redux-thunk";
import {getUserIdToken} from "../helpers/auth";
import {
  isDataAnalysisInterview,
  isMobileInterview,
  isWebInterview,
} from "../helpers/interview";
import {m2UploadUrl} from "../helpers/urls/interviewUrls";
import {FileDiff, Interview, ThunkDispatch} from "../reducers/types";
import {post} from "../util/fetch";
import {ZipUploadAction} from "./actionTypes";
import {setErrorMessage} from "./errorActions";
import {toast} from "./toastActions";

export const CLEAR_ZIP_UPLOAD_FILE = "CLEAR_ZIP_UPLOAD_FILE";
export const SET_ZIP_IS_UPLOADED = "SET_ZIP_IS_UPLOADED";
export const SET_ZIP_UPLOADED_FILES = "SET_ZIP_UPLOADED_FILES";
export const SET_ZIP_UPLOAD_PROGRESS = "SET_ZIP_UPLOAD_PROGRESS";
export const SET_ZIP_UPLOAD_FILE = "SET_ZIP_UPLOAD_FILE";
export const TOGGLE_SHOW_ZIP_UPLOAD_DIALOG = "TOGGLE_SHOW_ZIP_UPLOAD_DIALOG";

export const ZIP = "zip";
const initialProgressDelay = 200;
const uploadCompleteDelay = 1000;

function setZipUploadProgress(progress: number) {
  return {
    type: SET_ZIP_UPLOAD_PROGRESS,
    progress,
  };
}

export function getMaxFileUploadSizeMB(interview: Interview): number {
  let maxSize = 5;
  if (isWebInterview(interview)) {
    // It's really common for web candidates to include node_modules in their
    // zip and if so it will be about 2-3 MB. We want to prevent web candidates
    // from uploading node_modules so we'll set a lower max file size for them.
    maxSize = 1;
  } else if (isDataAnalysisInterview(interview)) {
    // The base code of the Data Analysis assessment includes data files and
    // images totalling ~10MB.
    maxSize = 15;
  }

  return maxSize;
}

function getMaxFileUploadSizeBytes(interview: Interview): number {
  return 1024 * 1024 * getMaxFileUploadSizeMB(interview);
}

export function uploadZipFile(
  interview: Interview,
  form: HTMLFormElement,
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async (dispatch: ThunkDispatch): Promise<any> => {
    const file: File = form[ZIP].files[0];
    if (file?.size > getMaxFileUploadSizeBytes(interview)) {
      let errorMsg =
        `Upload failed! Your zip file must be less than ` +
        `${getMaxFileUploadSizeMB(interview)}MB in size.`;
      if (isWebInterview(interview)) {
        errorMsg +=
          " Please ensure that your zip file doesn't include the " +
          "node_modules directory.";
      } else if (
        isMobileInterview(interview) &&
        interview.language === "flutter"
      ) {
        errorMsg += " Please make sure you run `flutter clean` before zipping.";
      } else if (interview.language === "rust") {
        errorMsg += " Please make sure you run `cargo clean` before zipping.";
      }
      dispatch(toast(errorMsg, 8000));
      form[ZIP].value = "";
      return;
    } else if (file?.name === "byteboard-tester.zip") {
      const errorMsg = `Upload failed! Please upload byteboard-interview.zip, not byteboard-tester.zip.`;
      dispatch(toast(errorMsg, 8000));
      form[ZIP].value = "";
      return;
    }
    dispatch({
      type: SET_ZIP_UPLOAD_FILE,
      file,
    });
    const initialProgressTimeout = setTimeout(() => {
      dispatch(setZipUploadProgress(0.1));
    }, initialProgressDelay);
    const url = m2UploadUrl(interview.id);
    const formData = new FormData(form);
    try {
      const response = await post(url, formData, {
        token: await getUserIdToken(),
        noContentType: true,
      });
      const json = await response.json();
      if (json.done) {
        clearTimeout(initialProgressTimeout);
        await dispatch(onUploadComplete(json.diff));
        if (
          (json.diff as FileDiff[]).every(
            (fileDiff) => fileDiff.numLinesAdded === 0,
          )
        ) {
          const errorMsg =
            "We did not detect a change in the number of " +
            "lines between your starter code and your submission. Please " +
            "confirm you've included the correct zip.";
          dispatch(toast(errorMsg, 10000));
        }
      } else {
        // Not sure what this case is - the upload completes but json.done is
        // false? Catch-all error handling: pass control flow to the
        // catch(err) section below
        throw new Error();
      }
    } catch (err) {
      if (err.data?.errorType === "filesAreUnchanged") {
        const errorMsg =
          "Error: You uploaded the base files, please " +
          "upload your changed files instead.";
        dispatch(toast(errorMsg, 10000));
        dispatch(clearUploadedZipFile());
      } else if (err.data?.errorType === "uploadedTesterFiles") {
        const errorMsg =
          "Error: You uploaded the tester files, please " +
          "upload your changed interview files instead.";
        dispatch(toast(errorMsg, 10000));
        dispatch(clearUploadedZipFile());
      } else {
        console.error("Unable to upload zip file", err);
        dispatch(setErrorMessage("Unable to upload zip file."));
      }
    }
  };
}

// We shouldn't have to worry about removing the file from its remote location
// because the candidate will replace it when they upload a new file.
export function clearUploadedZipFile(): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    dispatch({type: CLEAR_ZIP_UPLOAD_FILE});
  };
}

export function toggleZipUploadDialog(value?: boolean): ZipUploadAction {
  return {
    type: TOGGLE_SHOW_ZIP_UPLOAD_DIALOG,
    value,
  };
}

export function onUploadComplete(
  uploadedFiles: FileDiff[] = [],
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async (dispatch: ThunkDispatch): Promise<any> => {
    dispatch(setZipUploadProgress(1));
    // Wait a little bit so the progress bar can animate.
    await new Promise((resolve) => setTimeout(resolve, uploadCompleteDelay));
    dispatch({
      type: SET_ZIP_UPLOADED_FILES,
      value: uploadedFiles,
    });
    dispatch({
      type: SET_ZIP_IS_UPLOADED,
      value: true,
    });
  };
}
