import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CodeIcon from "@mui/icons-material/Code";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import classNames from "classnames";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {setErrorMessage} from "../../actions/errorActions";
import {LogAction, logFEInfo} from "../../actions/serverInfoActions";
import CenteredScreenContent from "../../components/CenteredScreenContent";
import {CoffeeAndClockIcon} from "../../components/CustomIcons";
import Spinner from "../../components/Spinner";
import {
  generateLanguagesForInterviewCenter,
  getDisplayInterviewType,
  getModuleDurationMs,
  isDataAnalysisInterview,
  isSecurityInterview,
  isStaffEngInterview,
} from "../../helpers/interview";
import {
  interviewFAQUrl,
  interviewGuideUrl,
  interviewHelpUrl,
  interviewUrl,
  testerUrl,
} from "../../helpers/urls/routerUrls";
import {useInterview} from "../../hooks/interview";
import timerImage from "../../img/interview-center/timers-and-time-reminders.png";
import {Interview} from "../../reducers/types";
import {msToMin} from "../../util/time";
import {AccommodationCallout, DestressCallout} from "./components/Callouts";
import {Title} from "./components/Title";
import {useStyles} from "./InterviewHubScreen.styles";

const zipUploadTimeMin = 5;

interface Props {
  atsId: string;
}

const technicalReasoningInstructions = (
  <ol>
    <li>
      <strong>Before the timer starts:</strong> Read all the instructions for
      this exercise before you hit the Start button (instructions will also be
      available through the duration of the interview).
    </li>
    <li>
      <strong>Read and Comment (~15 min):</strong> Read the document and
      understand the goals of the project and its technical design. Leave
      comments if you have questions or are making assumptions about any part of
      the document.
    </li>
    <li>
      <strong>Respond (~10 min):</strong> Answer questions left by your team in
      the comments. Explain your responses to help your team understand the
      reasons behind various decisions. Fully state your assumptions.
    </li>
    <li>
      <strong>Conclude (~15 min):</strong> Complete the 'Conclusion' section by
      answering the questions and explaining your reasoning. There is no right
      or wrong answer, but you should clearly communicate and justify your
      thought process.
    </li>
  </ol>
);

const technicalReasoningInstructionsWithoutConclusion = (
  <ol>
    <li>
      <strong>Before the timer starts:</strong> Read all the instructions for
      this exercise before you hit the Start button (instructions will also be
      available through the duration of the interview).
    </li>
    <li>
      <strong>Read and Comment (~15 min):</strong> Read the document and
      understand the goals of the project and its design. Leave comments if you
      have questions or are making assumptions about any part of the document.
    </li>
    <li>
      <strong>Respond (~25 min):</strong> Answer questions left by your team in
      the comments. Explain your responses to help your team understand the
      reasons behind various decisions. Fully state your assumptions.
    </li>
  </ol>
);

const staffTechnicalReasoningInstructions = (
  <ol>
    <li>
      <strong>Before the timer starts:</strong> Read all the instructions for
      this exercise before you hit the Start button (instructions will also be
      available through the duration of the interview).
    </li>
    <li>
      <strong>Read and Comment (~20 min):</strong> Read the document and
      understand the goals of the project and its technical design. Leave
      comments if you have questions or are making assumptions about any part of
      the document.
    </li>
    <li>
      <strong>Respond (~40 min):</strong> Answer questions left by your team in
      the comments. Explain your responses to help your team understand the
      reasons behind various decisions. Fully state your assumptions.
    </li>
  </ol>
);

export const codingExerciseInstructions = (
  <ol>
    <li>
      <strong>Before the timer starts:</strong> Read all the instructions for
      this exercise before you access the code. Accessing the code will
      automatically start the timer.
    </li>
    <li>
      <strong>Read (~15 min):</strong> Locate the README file and read each task
      so that you fully understand the feature requirements. Then, click through
      the files in the codebase and build an understanding of how the code fits
      together.
    </li>
    <li>
      <strong>Plan (~5 min):</strong> Take a moment to identify what strategies
      you plan to use to complete each task.
    </li>
    <li>
      <strong>Implement (~45 min):</strong> Start with the simplest way to
      complete each task. Think through all edge cases and do your best to
      implement a complete solution.
    </li>
    <li>
      <strong>Comment (5 min):</strong> If you do not have time to finish what
      you started, add comments describing what you would do with more time. Be
      as descriptive as possible. A human evaluator will be reading what you
      write and will consider every part of your response.
    </li>
  </ol>
);

export const staffCodingExerciseInstructions = (
  <ol>
    <li>
      <strong>Before the timer starts:</strong> Read all the instructions for
      this exercise before you access the code. Accessing the code will
      automatically start the timer.
    </li>
    <li>
      <strong>Read (~10 min):</strong> Locate the README file and read each task
      so that you fully understand the feature requirements. Then, click through
      the files in the codebase and build an understanding of how the code fits
      together.
    </li>
    <li>
      <strong>Plan (~5 min):</strong> Take a moment to identify what strategies
      you plan to use to complete each task.
    </li>
    <li>
      <strong>Implement (~40 min):</strong> Start with the simplest way to
      complete each task. Think through all edge cases and do your best to
      implement a complete solution.
    </li>
    <li>
      <strong>Comment (5 min):</strong> If you do not have time to finish what
      you started, add comments describing what you would do with more time. Be
      as descriptive as possible. A human evaluator will be reading what you
      write and will consider every part of your response.
    </li>
  </ol>
);

export const InterviewHubScreen: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const interview = useInterview(props.atsId);
  const search = useLocation().search;

  useEffect(() => {
    // logFEInfo pulls the interview from global state so make sure it is
    // loaded before calling
    if (interview) {
      dispatch(logFEInfo(LogAction.INTERVIEW_HUB_PAGE_VIEWED));
    }
  }, [interview]);

  if (!interview) {
    return <Spinner />;
  }

  // Props
  const interviewId = interview.id;
  const name = interview.candidateName;
  const interviewM2Type = interview.m2Type;
  const interviewLanguage = interview.language;

  const m1DurationMins = msToMin(getModuleDurationMs(interview, "m1"));
  const m2DurationMins = msToMin(getModuleDurationMs(interview, "m2"));
  const breakDurationMins = msToMin(getModuleDurationMs(interview, "break"));

  const totalDurationMins = m1DurationMins + m2DurationMins + breakDurationMins;
  const totalDurationHours = Math.floor(totalDurationMins / 60);
  const totalDurationMinsRemainder = totalDurationMins % 60;

  let totalDurationString = `${totalDurationMins} minutes`;

  if (totalDurationMins >= 120) {
    totalDurationString = `${totalDurationHours} hours and ${totalDurationMinsRemainder} minutes`;
  } else if (totalDurationMins >= 60) {
    totalDurationString = `${totalDurationHours} hour and ${totalDurationMinsRemainder} minutes`;
  }

  let showTestCodeEditorButton = false;
  if (interviewM2Type && interviewLanguage) {
    showTestCodeEditorButton = true;
  }

  // TODO: Add ability to add deadline
  const signupDeadline = interview.signupDeadline;

  if (!interview) {
    return <Spinner />;
  }

  // We used to use the `isOnsite` parameter to control access to the live coding interview hub.
  // This parameter is deprecated, so if candidates still have old links, we need to ask them to
  // get a new link from their interviewer.
  const isOnsiteHub = new URLSearchParams(search).get("isOnsite") === "true";
  if (isOnsiteHub) {
    dispatch(
      setErrorMessage(
        "This CodeCollab interview link has expired. Please ask your interviewer to re-send you an interview link.",
        {hideTips: true},
      ),
    );
  }

  return (
    <CenteredScreenContent size="normal">
      <main className={classes.root}>
        <Title
          title={
            (name ? `Hi ${name}, welcome ` : "Welcome") +
            " to your Interview Hub!"
          }
        />
        <AccommodationCallout interviewId={interviewId} />
        {/* Interview Grid */}
        <div className={classes.interviewOverviewGrid}>
          <div>
            <Typography variant="overline">Interview Type</Typography>
            <Typography variant="body2">
              {getDisplayInterviewType(interview)}
            </Typography>
            <React.Fragment>
              <Typography variant="overline" className={classes.mt8}>
                Languages Available
              </Typography>
              <Typography variant="body2">
                {generateLanguagesForInterviewCenter(interview)}
              </Typography>
            </React.Fragment>
          </div>
          <div className={classes.interviewOverviewGridActions}>
            <React.Fragment>
              <Button
                className={classes.buttonLink}
                fullWidth={true}
                href={interviewUrl(interviewId)}
                variant="contained"
              >
                Begin Interview
              </Button>
              {showTestCodeEditorButton && (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  href={testerUrl(
                    interviewId,
                    interviewM2Type,
                    interviewLanguage,
                  )}
                  className={classNames(
                    classes.buttonLink,
                    classes.buttonLinkSecondary,
                  )}
                >
                  Test Code Editor
                </Button>
              )}
            </React.Fragment>
          </div>
        </div>
        <React.Fragment>
          <Typography paragraph={true}>
            Schedule time on your calendar if you plan to take the interview at
            a later time.
          </Typography>
          {/* TODO: Add ability to add deadline */}
          {/* <Typography paragraph={true}>
          Your interview link is valid until <strong>{signupDeadline}</strong>.
        </Typography> */}
          <Typography paragraph={true}>
            This hub will be available anytime throughout your interview by
            accessing the{" "}
            <HelpOutlineOutlinedIcon
              className={classes.miniHelpButton}
              aria-label="help icon"
            />
            button in the top right corner of your interview platform.
          </Typography>
        </React.Fragment>
        {/* Separator */}
        <hr className={classes.separator} />
        {/* Technical requirements and setup */}
        <Typography variant="h2">Technical requirements and setup</Typography>
        <Typography paragraph={true}>
          Ensure you have the right hardware, network and software requirements
          to complete all parts of the interview. You will need:
        </Typography>
        <ul className={classes.list}>
          <li>
            <Typography>
              A computer with access to one of the following supported browsers:{" "}
              <strong>
                Google Chrome, Mozilla Firefox, Microsoft Edge, Safari, or
                Microsoft Internet Explorer 11+
              </strong>
              .
            </Typography>
          </li>
          <li>
            <Typography>
              Reliable <strong>internet</strong> connection.
            </Typography>
          </li>
          <div>
            <li>
              <Typography>
                A <strong>Gmail</strong> or{" "}
                <strong>Google Workspace account</strong>.
              </Typography>
            </li>
          </div>
        </ul>
        {/* What to expect in your interview */}
        <Typography variant="h2">What to expect in your interview</Typography>
        <React.Fragment>
          {totalDurationMins > 0 && (
            <Typography>
              The interview is <strong>{totalDurationString} long</strong> and
              includes two parts, outlined below.{" "}
              <strong>
                Your responses on Part 1 do not influence or impact the
                questions asked on Part 2.
              </strong>{" "}
              Each part of the interview will be timed and evaluated
              independently.
            </Typography>
          )}
          {/* Technical reasoning exercise */}
          <div className={classes.interviewSection}>
            <div
              className={classNames(
                classes.interviewSectionBorder,
                classes.interviewSectionBorderSecondary,
              )}
            ></div>
            <div className={classes.interviewSectionInfo}>
              <div
                className={classNames(
                  classes.interviewSectionHeading,
                  classes.removeTypographyMargin,
                )}
              >
                <DescriptionOutlinedIcon
                  className={classes.interviewSectionIcon}
                />
                <Typography variant="h3">
                  Technical Reasoning Exercise
                  {m1DurationMins > 0 && (
                    <span className={classes.interviewSectionSubInfo}>
                      <strong>{m1DurationMins} minutes</strong>
                    </span>
                  )}
                </Typography>
              </div>
              <Typography>
                Collaborate on a design document outlining potential
                implementation decisions for a product. Complete the document by
                filling in unfinished sections and answering any open questions.
              </Typography>
              <Typography variant="h4">Instructions</Typography>
              {getTechnicalReasoningInstructions(interview)}
              <div
                className={classNames(
                  classes.interviewSectionHeading,
                  classes.interviewSectionSubHeading,
                  classes.removeTypographyMargin,
                )}
              >
                <EmojiObjectsOutlinedIcon
                  className={classNames(
                    classes.interviewSectionIcon,
                    classes.interviewSectionIconSubHeading,
                  )}
                />
                <Typography variant="h4">
                  Tips for the Technical Reasoning Exercise
                </Typography>
              </div>
              <Typography paragraph={true}>
                Your interview will be evaluated by a real person. To ensure
                they understand your thought process:
              </Typography>
              <ul>
                <li>Fully state your assumptions.</li>
                <li>
                  Add comments in the document for any clarifications or
                  questions you have about the design.
                </li>
                <li>
                  Do not spend too much time responding to any one question.
                </li>
                <li>
                  Complete the exercise to the best of your ability and come
                  back to expand on answers if you have more time.
                </li>
                <li>
                  If there's a concept you are not familiar with, look it up!
                </li>
              </ul>
              <Typography>
                A detailed description of this exercise and a deeper
                understanding of performance expectations can be found in the{" "}
                <Link href={interviewGuideUrl(interviewId)}>
                  Interview Guide
                </Link>
                .
              </Typography>
            </div>
          </div>
          {/* Break */}
          <div className={classes.interviewSection}>
            <div
              className={classNames(
                classes.interviewSectionBorder,
                classes.interviewSectionBorderSecondaryLight,
              )}
            ></div>
            <div
              className={classNames(
                classes.interviewSectionInfo,
                classes.noBorder,
              )}
            >
              <div
                className={classNames(
                  classes.interviewSectionHeading,
                  classes.mb0,
                  classes.removeTypographyMargin,
                )}
              >
                <CoffeeAndClockIcon
                  className={classes.interviewSectionIcon}
                  color="inherit"
                />
                <Typography variant="h3">
                  Break
                  {breakDurationMins > 0 && (
                    <span className={classes.interviewSectionSubInfo}>
                      <strong>{breakDurationMins} minutes</strong>
                    </span>
                  )}
                </Typography>
              </div>
            </div>
          </div>
          {/* Coding implementation exercise */}
          <div className={classes.interviewSection}>
            <div
              className={classNames(
                classes.interviewSectionBorder,
                classes.interviewSectionBorderPrimary,
              )}
            ></div>
            <div className={classes.interviewSectionInfo}>
              <div
                className={classNames(
                  classes.interviewSectionHeading,
                  classes.removeTypographyMargin,
                )}
              >
                <CodeIcon className={classes.interviewSectionIcon} />
                <Typography variant="h3">
                  Coding Implementation Exercise
                  {m2DurationMins > 0 && (
                    <span className={classes.interviewSectionSubInfo}>
                      <strong>{m2DurationMins} minutes</strong>
                    </span>
                  )}
                </Typography>
              </div>
              <Typography>
                Implement a few aspects of the product described in Part 1.
                Navigate a small multi-file codebase and write clean and
                functional code.
              </Typography>
              <Typography variant="h4">Instructions</Typography>
              {isStaffEngInterview(interview)
                ? staffCodingExerciseInstructions
                : codingExerciseInstructions}
              <div
                className={classNames(
                  classes.interviewSectionHeading,
                  classes.interviewSectionSubHeading,
                  classes.removeTypographyMargin,
                )}
              >
                <EmojiObjectsOutlinedIcon
                  className={classNames(
                    classes.interviewSectionIcon,
                    classes.interviewSectionIconSubHeading,
                  )}
                />
                <Typography variant="h4">
                  Tips for the Coding Implementation Exercise
                </Typography>
              </div>
              <ol>
                <li>
                  Spend some time reading through the codebase and building a
                  mental model of how the code fits together.
                </li>
                <li>
                  Run your code often to detect bugs early on.
                  <ul>
                    <li>Pay attention to edge cases.</li>
                    <li>Try to submit code that does not crash.</li>
                    <li>
                      Consider adding test cases to catch edge cases or a future
                      regression.
                    </li>
                  </ul>
                </li>
                <li>
                  Your interview will be evaluated by a real subject-matter
                  expert, meaning all of your progress (including comments!)
                  will be taken into account. To ensure they understand your
                  thought process:
                  <ul>
                    <li>
                      Always state your assumptions, questions, or thoughts in
                      clear comments.
                    </li>
                    <li>
                      If you do not finish all your tasks or have additional
                      ideas for optimizations, describe what you would do with
                      more time by adding comments.
                    </li>
                  </ul>
                </li>
              </ol>
              <Typography paragraph={true}>
                If you forget syntax or encounter a concept you are not familiar
                with, look it up! Don't copy code as is, but try to make it your
                own and cite your sources.
              </Typography>
              <Typography>
                A detailed description of this exercise and a deeper
                understanding of performance expectations can be found in the{" "}
                <Link href={interviewGuideUrl(interviewId)}>
                  Interview Guide
                </Link>
                .
              </Typography>
            </div>
          </div>
          {/* Code Submission */}
          <div className={classes.interviewSection}>
            <div
              className={classNames(
                classes.interviewSectionBorder,
                classes.interviewSectionBorderSecondaryLight,
              )}
            ></div>
            <div
              className={classNames(
                classes.interviewSectionInfo,
                classes.noBorder,
              )}
            >
              <div
                className={classNames(
                  classes.interviewSectionHeading,
                  classes.mb0,
                  classes.removeTypographyMargin,
                )}
              >
                <CoffeeAndClockIcon
                  className={classes.interviewSectionIcon}
                  color="inherit"
                />
                <Typography variant="h3">
                  Code Submission
                  {
                    <span className={classes.interviewSectionSubInfo}>
                      <strong>{zipUploadTimeMin} minutes</strong>
                    </span>
                  }
                </Typography>
              </div>
              <div>
                <Typography variant="h4">
                  If you are using the Cloud Shell Editor the code will
                  automatically be submitted and the timer will stop. If you are
                  using your own IDE you will have {zipUploadTimeMin} minutes
                  after the interview timer ends to use the instructions to zip
                  and upload your code. Note that submitting early will end your
                  interview and cannot be undone.
                </Typography>
              </div>
            </div>
          </div>
          {/* Section - Timers and time reminders */}
          <Typography variant="h2">Timers and time reminders</Typography>
          <Typography>
            A visual timer is present in the top right corner of the screen on
            your interview platform. You may click to toggle showing/hiding the
            timer at any time. Regardless of whether your timer is hidden, in
            addition to the visual timer there will be an audio reminder to
            signal when you have 10 minutes left on the interview. Check the
            volume of your system to ensure you receive these alerts.
          </Typography>
          <img src={timerImage} alt="Indicating where timer is on the screen" />
          {/* Call Out */}
          <div
            className={classNames(
              classes.callout,
              classes.calloutWithIcon,
              classes.calloutWithInline,
              classes.mt0,
            )}
          >
            <WatchLaterOutlinedIcon />
            <Typography>
              Timing the interview ensures that every candidate has a consistent
              and fair interview. We also want to make sure that you are able to
              demonstrate as much of your skill set as candidates who might have
              fewer obligations.
            </Typography>
          </div>
          <Typography paragraph={true}>
            The duration of each part of the Byteboard interview is set such
            that you have enough time to showcase your strength in a range of
            engineering skills. Tasks are designed to be open-ended just like
            they would be on a real engineering job. So there will always be
            parts you won't be able to get to. Try not to focus on finishing
            every part of the interview and instead focus on doing your best on
            tasks where you get to show off your skills.
          </Typography>
          {/* Section - Additional Reading */}
          {/* Separator */}
          <hr className={classes.separator} />
          {/* Additional Reading */}
          <Typography variant="h2">Additional reading (optional)</Typography>
          <div className={classes.additionalReadingSection}>
            {/* Read the interview guide */}
            <Link
              className={classNames(
                classes.additionalReadingBlock,
                classes.blockAsLink,
              )}
              underline="none"
              href={interviewGuideUrl(interviewId)}
            >
              <div
                className={classNames(
                  classes.calloutWithIcon,
                  classes.additionalReadingBlockHeader,
                  classes.removeTypographyMargin,
                )}
              >
                <TipsAndUpdatesOutlinedIcon
                  className={classNames(
                    classes.interviewSectionIcon,
                    classes.interviewSectionIconSubHeading,
                  )}
                />
                <Typography variant="h3">Read the interview guide</Typography>
              </div>
              <div className={classes.additionalReadingBlockContent}>
                <Typography>
                  Familiarize yourself with the interview platform, performance
                  expectations and wellness tips that will help set you up for
                  interview success.
                </Typography>
              </div>
              <div className={classes.additionalReadingBlockCTA}>
                Read more{" "}
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </div>
            </Link>
            {/* FAQ */}
            <Link
              className={classNames(
                classes.additionalReadingBlock,
                classes.blockAsLink,
              )}
              underline="none"
              href={interviewFAQUrl(interviewId)}
            >
              <div
                className={classNames(
                  classes.calloutWithIcon,
                  classes.additionalReadingBlockHeader,
                  classes.removeTypographyMargin,
                )}
              >
                <QuizOutlinedIcon
                  className={classNames(
                    classes.interviewSectionIcon,
                    classes.interviewSectionIconSubHeading,
                  )}
                />
                <Typography variant="h3">FAQ</Typography>
              </div>
              <div className={classes.additionalReadingBlockContent}>
                <Typography>
                  Familiarize yourself with the questions that are commonly
                  asked by candidates.
                </Typography>
              </div>
              <div className={classes.additionalReadingBlockCTA}>
                Get Answers{" "}
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </div>
            </Link>
            {/* Help and Troubleshooting */}
            <Link
              className={classNames(
                classes.additionalReadingBlock,
                classes.blockAsLink,
              )}
              underline="none"
              href={interviewHelpUrl(interviewId)}
            >
              <div
                className={classNames(
                  classes.calloutWithIcon,
                  classes.additionalReadingBlockHeader,
                  classes.removeTypographyMargin,
                )}
              >
                <SupportAgentIcon
                  className={classNames(
                    classes.interviewSectionIcon,
                    classes.interviewSectionIconSubHeading,
                  )}
                />
                <Typography variant="h3">Help and troubleshooting</Typography>
              </div>
              <div className={classes.additionalReadingBlockContent}>
                <Typography>
                  Use these topics to debug issues you might run into during
                  your interview.
                </Typography>
              </div>
              <div className={classes.additionalReadingBlockCTA}>
                Get Help{" "}
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </div>
            </Link>
          </div>
        </React.Fragment>
        <DestressCallout interviewId={interviewId} />
      </main>
    </CenteredScreenContent>
  );
};

export function getTechnicalReasoningInstructions(
  interview: Interview,
): JSX.Element {
  if (isDataAnalysisInterview(interview) || isSecurityInterview(interview)) {
    return technicalReasoningInstructionsWithoutConclusion;
  }
  if (isStaffEngInterview(interview)) {
    return staffTechnicalReasoningInstructions;
  }
  return technicalReasoningInstructions;
}
